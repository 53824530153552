html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #525252;
  margin-bottom: 116px;
}

/* Brand Colours */
.bg-brand-dark-blue {
  background-color: #046ccc !important;
}

.bg-brand-light-blue {
  background-color: #51b5ec !important;
}

.bg-brand-dark-pink {
  background-color: #d6315d !important;
}

.bg-brand-light-pink {
  background-color: #e86da2 !important;
}

.bg-brand-steel {
  background-color: #c8c7e5 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-brand-blue-gradient {
  background: linear-gradient(135deg, #046ccc 0%, #046ccc 25%, #51b5ec 100%) !important;
}

.bg-brand-pink-gradient {
  background: linear-gradient(135deg, #d6315d 0%, #d6315d 25%, #e86da2 100%) !important;
}

.border-brand-light-blue {
  border-color: #51b5ec;
}

.font-brand-dark-blue {
  color: #046ccc !important;
}

.font-brand-light-blue {
  color: #51b5ec !important;
}

.font-brand-dark-pink {
  color: #d6315d !important;
}

.font-brand-light-pink {
  color: #e86da2 !important;
}

.font-brand-steel {
  color: #c8c7e5 !important;
}

.font-brand-white {
  color: #fff !important;
}

.font-brand-gray {
  color: #606060 !important;
}

button>svg {
  pointer-events: none;
}

/* FOOTER */
.footer {
  /* padding:50px 0 55px; */
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 96px;
}

.footer__copy {
  margin-top: 40px;
  font-size: 14px;
}

.footer_img {
  height: 500px;
  width: auto;
}

/* PAGE LOADER */

.loader-body {
  background-color: rgba(87, 87, 86, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 0px;
  left: 0px;
}

.loader {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 60px);
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  border: 16px solid #bcbcbb;
  width: 120px;
  height: 120px;
  border-top-color: #046ccc;
  border-radius: 50%;
  animation: coloredspin 8s linear infinite;
  z-index: 5000;
}

@keyframes coloredspin {
  0% {
    transform: rotate(0deg) scale(1);
    border-top-color: #046ccc;
  }

  25% {
    border-top-color: #d6315d;
  }

  50% {
    transform: rotate(720deg) scale(1.05);
    border-top-color: #51b5ec;
  }

  75% {
    border-top-color: #e86da2;
  }

  100% {
    transform: rotate(1440deg) scale(1);
    border-top-color: #046ccc;
  }
}

/* Homepage */
.homepage-jumbotron {
  width: 100%;
  border-radius: 10px;
}

.list-group-numbered {
  list-style: decimal inside;
  list-style-position: outside;
}

.list-group-alpha {
  list-style: lower-alpha inside;
  list-style-position: outside;
}

.list-group-roman {
  list-style: lower-roman inside;
  list-style-position: outside;
}

.list-group-alpha>li,
.list-group-roman>li,
.list-group-numbered>li {
  display: list-item
}

/* Navbar */

.pmm-navbar {
  min-height: 96px;
  background: #d6315d;
  background: linear-gradient(135deg, #d6315d 0%, #d6315d 25%, #e86da2 100%);
  box-shadow: 0 4px 10px 0 rgba(214, 49, 93, 0.6)
}

/* Hero Banner */
.hero-img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  height:calc(25vh);
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
              url("/images/chopping.jpg");
  background-size: cover;
  background-position: 50% 70%;
  box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.6);
  position: relative;
}

.hero-text {
  width:90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.hero-header {
  margin:0;
  width:80%;
  font-size: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-block-end: 0;
  margin-block-start:0;
}

.hero-header-mob {
  width:100%;
  font-size: 3em;
  margin:0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-block-end: 0;
  margin-block-start:0;
}

/* Sign-In/Sign-UP */

.auth-form {
  max-width: 600px;
  margin:auto;
  padding: 15px;
  border: 2px solid #046ccc;
  border-radius: 15px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.6);
}

.auth-form .input-group-prepend, 
.w-50px {
  width: 50px;
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  width: 100%;
}

.input-group-text svg {
  margin:auto;
  color:#fff;
}

.fa-li {
  top: 0.2em;
}

